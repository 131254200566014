export const colorChipsForStroke = [
  '#008FFB', //prediction(blue)
  '#00E396', //ground truth(green)
  '#FFFFFF', //upper bounds(white)
  '#FFFFFF', //lower bounds(white)
  '#FF9936',
  '#FFE346',
  '#893CC1',
  '#8779ED',
  '#617EFF',
  '#45E516',
  '#F77070',
  '#FF3D50',
  '#52C2CC',
  '#FF3D50',
  '#63C1FA',
  '#1A73FF',
  '#617EFF',
  '#8779ED',
  '#893CC1',
]

export const colorChipsForFill = [
  '#008FFB', //prediction(blue)
  '#FFFFFF', //ground truth(white)
  '#008FFB', //upper bounds (prediction과 색상 맞춤)
  '#FFFFFF', //lower bounds (white)
  '#FF9936',
  '#FFE346',
  '#893CC1',
  '#8779ED',
  '#617EFF',
  '#45E516',
  '#F77070',
  '#FF3D50',
  '#52C2CC',
  '#FF3D50',
  '#63C1FA',
  '#1A73FF',
  '#617EFF',
  '#8779ED',
  '#893CC1',
]
