// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("ITC Avant Garde Gothic Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("NotoSansKR.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Helvetica.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("HelveticaNeueBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("HelveticaNeue Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("FuturaCyrillicLight.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'ITC Avant Garde Gothic';
  font-style: normal;
  /* font-weight: 700; */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('ttf');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('ttf');
}

@font-face {
  font-family: 'Helvetica Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('ttf');
}

@font-face {
  font-family: 'Futura';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('ttf');
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fontStyle.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,sBAAsB;EACtB,+DAAgE;AAClE;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,0DAAwC;AAC1C;;AAEA;EACE,6BAA6B;EAC7B,0DAAuC;AACzC;;AAEA;EACE,6BAA6B;EAC7B,+DAAoD;AACtD;;AAEA;EACE,qCAAqC;EACrC,0DAAmD;AACrD;;AAEA;EACE,qBAAqB;EACrB,0DAAiD;AACnD","sourcesContent":["@font-face {\n  font-family: 'ITC Avant Garde Gothic';\n  font-style: normal;\n  /* font-weight: 700; */\n  src: url('ITC Avant Garde Gothic Medium.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Noto Sans';\n  font-style: normal;\n  src: url('NotoSansKR.ttf') format('ttf');\n}\n\n@font-face {\n  font-family: 'Helvetica Neue';\n  src: url('Helvetica.ttf') format('ttf');\n}\n\n@font-face {\n  font-family: 'Helvetica Bold';\n  src: url('HelveticaNeueBold.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Helvetica Neue Regular';\n  src: url('HelveticaNeue Regular.ttf') format('ttf');\n}\n\n@font-face {\n  font-family: 'Futura';\n  src: url('FuturaCyrillicLight.ttf') format('ttf');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
