// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container{
  overflow-x: scroll;
}

.containerBody{
  height:600px;
}

.x-axis-label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color:'#575757';
  padding-top: 10px;
}

/* .y-axis-label {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%) rotate(-90deg); 
  font-size: 13px;
  font-weight: bold;
  padding-right: 10px;
} */

#legend-container {
  position: absolute;
  top: 0;
  left: 100%; /* 차트 옆에 고정 */
  transform: translateX(-100%); /* 범례를 차트의 오른쪽에 고정 */
  white-space: nowrap;
  padding-left: 20px;
  padding-right:45px;
}

.info-icon {
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height:13px;
}

`, "",{"version":3,"sources":["webpack://./src/views/AIModelGenerator/Visualization/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;;;;;;;;GAQG;;AAEH;EACE,kBAAkB;EAClB,MAAM;EACN,UAAU,EAAE,aAAa;EACzB,4BAA4B,EAAE,oBAAoB;EAClD,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".chart-container{\n  overflow-x: scroll;\n}\n\n.containerBody{\n  height:600px;\n}\n\n.x-axis-label {\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  transform: translateX(-50%);\n  font-size: 14px;\n  color:'#575757';\n  padding-top: 10px;\n}\n\n/* .y-axis-label {\n  position: absolute;\n  top: 50%;\n  left: -20px;\n  transform: translateY(-50%) rotate(-90deg); \n  font-size: 13px;\n  font-weight: bold;\n  padding-right: 10px;\n} */\n\n#legend-container {\n  position: absolute;\n  top: 0;\n  left: 100%; /* 차트 옆에 고정 */\n  transform: translateX(-100%); /* 범례를 차트의 오른쪽에 고정 */\n  white-space: nowrap;\n  padding-left: 20px;\n  padding-right:45px;\n}\n\n.info-icon {\n  font-size: 18px;\n  cursor: pointer;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  line-height:13px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
