export const colorChipsForStroke = [
  '#FF7F00', //prediction(orange)
  '#008FFB', //ground truth(blue)
  '#FFFFFF', //upper bounds(white)
  '#FFFFFF', //lower bounds(white)
  '#FF9936',
  '#FFE346',
  '#893CC1',
  '#8779ED',
  '#617EFF',
  '#45E516',
  '#F77070',
  '#FF3D50',
  '#52C2CC',
  '#FF3D50',
  '#63C1FA',
  '#1A73FF',
  '#617EFF',
  '#8779ED',
  '#893CC1',
]

export const colorChipsForFill = [
  '#FF7F00', //prediction(orange)
  '#FFFFFF', //ground truth(white)
  '#FF7F00', //upper bounds (prediction과 색상 맞춤)
  '#FFFFFF', //lower bounds (white)
  '#FF9936',
  '#FFE346',
  '#893CC1',
  '#8779ED',
  '#617EFF',
  '#45E516',
  '#F77070',
  '#FF3D50',
  '#52C2CC',
  '#FF3D50',
  '#63C1FA',
  '#1A73FF',
  '#617EFF',
  '#8779ED',
  '#893CC1',
]
